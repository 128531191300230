<template>
<div class="accordion-item">
  <div class="accordion-header collapsed" data-bs-toggle="collapse" aria-expanded="false">
    <div class="d-flex justify-content-between">
      <div class="pe-3">
        <p class="staking__number mt-0 mb-0 text-grey10">{{ $t('exchange__title1') }} №{{data.id}}</p>
        <p class="mt-0 mb-0 text-grey40">
          {{ $t('staking__avail') }}
          <span class="text-grey10">{{data.available[0]}}</span> {{$t('d')}}
          <span class="text-grey10">{{data.available[1]}}</span> {{$t('h')}}
          <span class="text-grey10">{{data.available[2]}}</span> {{$t('min')}}
        </p>
      </div>
      <div class="d-flex text-right">
        <div class="staking__text">
          <p class="staking__value mt-0 mb-0 text-grey10">{{data.term}}({{data.percent}})</p>
          <p class="mt-0 mb-0 text-success">+{{data.day_amount}}</p>
        </div>
        <div class="staking__btns">
          <button :class="{ active: dropdown }" class="staking__btn-arrow" @click="toggleDropdown"></button>
          <button class="staking__btn-plus" data-bs-toggle="modal" @click="modalEdit = true"></button>
        </div>
      </div>
    </div>
  </div>
  <div :class="{ show: dropdown }" class="accordion-collapse collapse" data-bs-parent="#accordionStaking">
    <div class="accordion-body">
      <div class="d-flex justify-content-between">
        <div class="pe-3">
          <p class="mt-0 mb-0">
          {{$t('staking_sum')}}: <span class="text-white">{{data.deposit}} REND</span>
          </p>
          <p class="mt-0 mb-0">
          {{$t('staking_datestart')}}: <span class="text-white">{{data.datetime_start}}</span>
          </p>
          <p class="mt-0 mb-0">
          {{$t('staking_term')}}: <span class="text-white">{{data.term}} {{$t('d')}}</span>
          </p>
          <p class="mt-0 mb-0">
          {{$t('staking_dateend')}}: <span class="text-white">{{data.datetime_end}}</span>
          </p>
          <p class="mt-0 mb-0">
          {{$t('staking_cash')}}: <span class="text-white">{{data.accumulated}} REND</span>
          </p>
          <p class="mt-0 mb-0">
          {{$t('staking_allcash')}}: <span class="text-white">{{data.accumulated_period}} REND</span>
          </p>
          <p class="mt-0 mb-0">
          {{$t('staking_residue')}}: <span class="text-white">{{data.available[0]}} {{$t('d')}} {{data.available[1]}} {{$t('h')}} {{data.available[2]}} {{$t('min')}}</span>
          </p>
        </div>
        <div class="d-flex text-right">
          <div class="staking__text">
            <p class="staking__value mt-0 mb-0 text-grey40">{{data.datetime_start}}</p>
          </div>
          <div class="staking__btns">
            <a href="javascript:void(0)" class="link staking__cancell" @click="modalDelete = true">
              {{ $t('cancelbtn') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <base-modal :isShow="modalDelete" @close="modalCloseDelete" @ok="modalOkDelete">
    <h2 class="modal-title mb-3">{{ $t('atentiontitle') }}</h2>
    <p v-if="data.warning_stop" class="modal__text mb-1  text-center">
      {{ $t('atentiontext') }}
    </p>
    <p v-else class="modal__text mb-1  text-center">
      {{ $t('atentiontext1') }}
    </p>

    <template v-slot:button>
      {{$t('confirm_btn')}}
    </template>
  </base-modal>


  <base-modal :isShow="modalEdit" @close="modalCloseEdit" @ok="modalOkEdit">
    <div>
      <h2 class="modal-title mb-2">{{ $t('stake_charge') }}</h2>
      <div class="d-flex justify-content-between mb-3">
        <div class="modal-rend__text text-white">REND</div>
        <div class="modal-rend__value text-white">{{rend}}</div>
      </div>
      <div class="control control--md modal-rend__control mb-3">
        <input type="number" name="modalRend" class="control__input" v-bind:placeholder="$t('sum_place')" required v-model:value="modalEditAmount">
        <span class="control__cur">REND</span>
      </div>
      <div>
        <div class="range form__range">
          <input type="range" class="range__control" name="range" step="25" min="0" :max="rend" v-model:value="modalEditAmount">
        </div>
      </div>
    </div>

    <template v-slot:button>
      {{ $t('stake_charge_btn') }}
    </template>
  </base-modal>
</div>
</template>

<script>
import { api } from '@/api.ts';

import { readSymbol } from '@/store/main/getters';

import logoUSDT from '@/assets/img/coins/usdt.svg';
import logoSTAB from '@/assets/img/coins/stab.svg';
import logoREND from '@/assets/img/coins/rend.svg';

import BaseModal from '@/components/modals/BaseModal';


export default {
  components: {
    BaseModal
  },
  name: 'StakeActive',
  props: ['data', 'rend'],
  data() { return {
    modalDelete: false,
    modalEdit: false,
    modalEditAmount: 0,
    dropdown: false
  }},
  computed: {
    url() {
      return this.data.hash;
    },
    symbol() {
      return readSymbol(this.$store);
    },
    logo() {
      if (this.symbol === 'usdt') {
        return logoUSDT;
      } else if (this.symbol === 'stab') {
        return logoSTAB;
      } else if (this.symbol === 'rend') {
        return logoREND;
      }
    },
  },
  methods: {
    toggleDropdown() {
      if(this.dropdown){
        this.dropdown = false;
      }else{
        this.dropdown = true;
      }
    },

    modalCloseDelete() {
      this.modalDelete = false;
    },

    async modalOkDelete() {
      const response = await this.deleteStack();
      if(response === true) {
        this.modalDelete = false;
        this.$emit('modalOkDelete');
      }
    },

    modalCloseEdit() {
      this.modalEdit = false;
    },

    async modalOkEdit() {
      const response = await this.editStack();
      if(response === true) {
        this.modalEdit = false;
        this.$emit('modalOkEdit');
      }
    },

    deleteStack() {
      this.$noty.info(this.$t("wait"), { timeout: 4000, progressBar: true });
      return api('stake', 'stopStack', {id: this.data.id})
      .then( r => {
        // console.log(stopStack, r);
        if(r.data.status) {
          this.$noty.success(this.$t('success'), { timeout: 2000, progressBar: true });
          return true;
        } else {
          return this.$noty.error(r.data.message, { timeout: 3000, progressBar: true });
        }
      });
    },

    editStack(methodId, id, amount, period){
      if(amount === 0) { return this.$noty.error(this.$t('wrongAmount'), { timeout: 2000, progressBar: true })};
      if(amount < 100) { return this.$noty.error(this.$t('minAmount') + 100, { timeout: 3000, progressBar: true })};

      this.$noty.info(this.$t("wait"), { timeout: 4000, progressBar: true });
      return api('stake', 'editStack', {id: this.data.id, amount: this.modalEditAmount, period: 0})
      .then( r => {
        // console.log(methodId, r);
        if(r.data.status){
          this.$noty.success(this.$t('success'), { timeout: 2000, progressBar: true });
          return true;
        }else{
          this.$noty.error(r.data.message, { timeout: 2000, progressBar: true });
        }
      });
    },
  }
}
</script>

<style scoped>

</style>
