<template>
  <main class="main">
    <div class="content">
      <div class="container content__container content__container--mw100">
        <div class="staking">
          <div class="staking__columns">
            <div class="staking__column">
              <div class="box staking__box rend">
                <div class="rend__head">
                  <h2 class="title staking__title">{{$t('balance')}}</h2>
                </div>
                <div class="rend__body">
                  <div class="row align-items-center">
                    <div class="col">
                      <div class="balance-rend">
                        <h2 class="balance-rend__title">{{$t('balance_rend')}}</h2>
                        <div class="balance-rend__inner">
                          <div class="balance-rend__icon" style="background: rgb(0, 194, 115) !important;">
                            <img class="balance-rend__img" src="@/assets/img/rend.svg" alt="">
                          </div>
                          <div class="balance-rend__content">
                            <div class="balance-rend__value" id="rend_balance">{{this.total.rend}}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col">
                      <div class="balance-rend">
                        <h2 class="balance-rend__title">{{$t('in_stacking')}}</h2>
                        <div class="balance-rend__inner">
                          <div class="balance-rend__icon">
                            <img class="balance-rend__img" src="@/assets/img/rend.svg" alt="">
                          </div>
                          <div class="balance-rend__content">
                            <div class="balance-rend__value" id="rend_balance">{{total.stake}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--<div class="col-lg-5 col">
                      <div class="rend__topup">
                        <a href="#" class="link mb-2">Как пополнить баланс?</a>
                        <button class="btn btn-gray-outline rend__topup-btn" data-bs-toggle="modal"
                            data-bs-target="#modalGetBitcoin">Пополнить</button>
                      </div>
                    </div>-->
                  </div>
                </div>
                <div class="rend__foot">
                  <div class="row align-items-center">
                    <div class="col-xl-7 col-lg-12 col-sm-7">
                      <div class="staking__info">
                        <vue-apex-charts
                          height="150"
                          width="335"
                          ref="chartStaking"
                          id="chartStaking"
                          dir="ltr"
                          :options="chartStaking.options"
                          :series="chartStaking.series">
                        </vue-apex-charts>
                        <!--<div id="chartStaking" class="apex-charts" dir="ltr"></div>-->
                      </div>
                    </div>
                    <div class="col-xl-5 col-lg-12 col-sm-5">
                      <button class="btn btn-info rend__btn-pass-staking"
                              @click="modalCreate.status = true">{{$t('go_stacking')}}</button>
                    </div>
                  </div>
                </div>
              </div>
              <!--balance.end-->

            </div>
            <div class="staking__column">

              <!--profitability.begin-->
              <div class="box staking__box profitability">
                <div class="profitability__head">

                  <div class="balance-rend">
                    <h2 class="balance-rend__title">{{$t('balance_rend')}}</h2>
                    <div class="balance-rend__inner">
                      <div class="balance-rend__icon" style="background: rgb(0, 194, 115) !important;">
                        <img class="balance-rend__img" src="@/assets/img/rend.svg" alt="">
                      </div>
                      <div class="balance-rend__content">
                        <div class="balance-rend__value">{{total.rend}}</div>
                        <div class="balance-rend__caption">= {{total.rend}} REND (${{total.rend_usd}})</div>
                      </div>
                    </div>
                  </div>

                  <div class="profitability__annual">
                    <div class="profitability__annual-text">{{$t('year_income')}}</div>
                    <div class="profitability__annual-value">{{all_percent}}%</div>
                    <a href="#" class="link">{{$t('how_in_stacking')}}</a>
                  </div>

                </div>
                <div class="profitability__body">

                  <div class="smart-table smart-table--profitability">
                    <table class="table smart-table__table">
                      <thead class="smart-table__thead">
                      <tr class="smart-table__row">
                        <th class="smart-table__cell" data-column-id="rend">REND</th>
                        <th class="smart-table__cell text-right" data-column-id="days">365 {{$t('days')}}</th>
                        <th class="smart-table__cell text-right" data-column-id="days">180 {{$t('days')}}</th>
                        <th class="smart-table__cell text-right" data-column-id="days">120 {{$t('days')}}</th>
                        <th class="smart-table__cell text-right" data-column-id="days">90 {{$t('days')}}</th>
                        <th class="smart-table__cell text-right" data-column-id="days">60 {{$t('days')}}</th>
                        <th class="smart-table__cell text-right" data-column-id="days">30 {{$t('days')}}</th>
                        <th class="smart-table__cell text-right" data-column-id="days">14 {{$t('days')}}</th>
                        <th class="smart-table__cell text-right" data-column-id="days">{{$t('no_days')}}</th>
                      </tr>
                      </thead>

                      <tbody class="smart-table__tbody scrollbar">

                      <template style="display: contents">
                        <staking-map v-for="(item,index) in staking.map" :data="item" :key="`stakeMap-${index}`"></staking-map>
                      </template>

                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
              <!--profitability.end-->

            </div>
            <div class="staking__column">

              <!--payout-history.begin-->
              <div class="box staking__box payout-history">
                <div class="payout-history__head">
                  <h2 class="title staking__title">{{$t('history_withdraw_title')}}</h2>
                </div>
                <div class="payout-history__body">
                  <div class="smart-table smart-table--payout-history">
                    <table class="table smart-table__table">
                      <thead class="smart-table__thead">
                      <tr class="smart-table__row">
                        <th class="smart-table__cell" data-column-id="staiking">{{$t('stacking')}}</th>
                        <th class="smart-table__cell text-right" data-column-id="sum">{{$t('incoming')}}</th>
                        <th class="smart-table__cell" data-column-id="date">{{$t('date')}}</th>
                        <th class="smart-table__cell text-right" data-column-id="time">{{$t('time')}}</th>
                      </tr>
                      </thead>
                      <tbody class="smart-table__tbody scrollbar">

                      <template style="display: contents"><history-stak v-for="(item,index) in history.stak" :data="item" :key="`historyStak-${index}`"></history-stak></template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!--payout-history.end-->

            </div>
            <div class="staking__column">
              <div class="box staking__box">

                <div class="tabs staking__tabs">
                  <ul class="nav nav-tabs" id="tabStaking">
                    <li class="nav-item">
                      <button class="nav-link" v-bind:class="{'active': activeTabStaking === 0}"
                              @click="changeActiveTabStaking(0)"
                              type="button" aria-selected="true">{{$t('in_active')}}</button>
                    </li>
                    <li class="nav-item">
                      <button class="nav-link" v-bind:class="{'active': activeTabStaking === 1}"
                              @click="changeActiveTabStaking(1)"
                              type="button" aria-selected="false">{{$t('out_active')}}</button>
                    </li>
                  </ul>
                  <div class="tab-content">
                    <div class="tab-pane fade pt-0" v-bind:class="{'show active': activeTabStaking === 0}"
                         id="tabStakingActive" role="tabpanel">
                      <div class="accordion staking__accordion" id="accordionStaking">
                        <template style="display: contents">
                          <stake-active v-for="(item, index) in stake.active" :data="item" :rend="total.rend" :key="`stakeActive-${index}`" :modalStatusFetback="modalStatus" @modalOkEdit='sendModalStackEdit' @modalOkDelete='sendModalStackDelete'></stake-active>
                        </template>
                      </div>
                    </div>

                    <div class="tab-pane fade pt-0" v-bind:class="{'show active': activeTabStaking === 1}"
                         id="tabStakingCancelled" role="tabpanel" aria-labelledby="profile-tab">
                      <template style="display: contents"><passive-stak v-for="(item, index) in stake.passive" :data="item" :key="`passiveStak-${index}`"></passive-stak></template>

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>


        </div>

        <div class="modal fade" id="modalGetBitcoin" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header pb-0 mb-0 text-center">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <h2 class="modal-title mb-2">
                  {{$t('give_bitcoin')}}
                </h2>
                <p class="modal__text mb-1">{{$t('give_from')}}</p>
              </div>
              <div class="modal-body">
                <div class="tabs">
                  <div class="tabs__head">
                    <ul class="nav nav-tabs">
                      <li class="nav-item">
                        <button class="nav-link active" data-bs-toggle="tab"
                            data-bs-target="#tabGetExternalSource" type="button" aria-selected="true">{{$t('out_source')}}</button>
                      </li>
                      <li class="nav-item">
                        <button class="nav-link" data-bs-toggle="tab"
                            data-bs-target="#tabGetAccountBetweenOcean" type="button"
                            aria-selected="false">{{$t('account_between')}}</button>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-content">
                    <div class="tab-pane fade show active" id="tabGetExternalSource">
                      <form action="#" class="form pe-0 ps-0" id="formGetExternalSource" method="POST">
                        <div class="form__body mb-3">
                          <div class="form-item mb-2 mt-1 asset">
                            <label class="form__label d-block asset__label text-center mb-2">{{$t('asset')}}</label>
                            <div class="asset__inner d-flex align-items-center justify-content-center">
                              <div class="cryptocurrency">
                                <img class="cryptocurrency__img"
                                   src="@/assets/img/cryptocurrency/btc.png" alt="">
                                <div class="cryptocurrency__name ms-2">BTC</div>
                              </div>
                            </div>
                          </div>
                          <div class="alert align-items-start">
                            <svg class="alert__icon">
                              <use xlink:href="#i-warning"></use>
                            </svg>
                            <div class="alert__text" v-html="this.$t('send_btc_text')">
                            </div>
                          </div>
                          <div class="form-item mb-4">
                            <label for="sendBitcoinAddress" class="form__label text-grey40 d-block">
                             {{$t('send_btn_to_address')}}
                            </label>
                            <input type="text" name="sendBitcoinAddress" class="form__control"
                                   id="sendBitcoinAddress" placeholder="Введите адрес"
                                   value="1afeHDGhf56ssfqw5fsGj1afeHDG" required>
                            <svg class="form__control-icon form__control-icon--copy">
                              <use xlink:href="#i-copy"></use>
                            </svg>
                            <svg class="form__control-icon">
                              <use xlink:href="#i-scan"></use>
                            </svg>
                          </div>
                          <div class="form-item mb-3">
                            <div class="row justify-content-center">
                              <div class="col-6">
                                <div class="qr-code">
                                  <img class="qr-code__img d-block w-100" src="@/assets/img/qr.png" alt="">
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row justify-content-center mt-3">
                            <div class="col-8">
                              <button type="submit" class="btn btn-md btn-primary form__submit w-100">
                                {{$t('closeBtn')}}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div class="tab-pane fade" id="tabGetAccountBetweenOcean">
                      <form action="#" class="form pe-0 ps-0" id="formGetAccountBetweenOcean" method="POST">
                        <div class="form__body mb-3">
                          <div class="form-item mb-2 mt-1 asset">
                            <label class="form__label d-block asset__label text-center mb-2">{{$t('asset')}}</label>
                            <div class="asset__inner d-flex align-items-center justify-content">
                              <div class="cryptocurrency">
                                <img class="cryptocurrency__img"
                                   src="@/assets/img/cryptocurrency/btc.png" alt="">
                                <div class="cryptocurrency__name ms-2">BTC</div>
                              </div>
                            </div>
                          </div>
                          <div class="alert align-items-start">
                            <svg class="alert__icon">
                              <use xlink:href="#i-warning"></use>
                            </svg>
                            <div class="alert__text" v-html="this.$t('send_btc_text')">
                            </div>
                          </div>
                          <div class="form-item mb-4">
                            <label for="betweenOceanGetAddress" class="form__label text-grey40 d-block">{{$t('send_btn_to_address')}}</label>
                            <input type="text" name="betweenOceanGetAddress" class="form__control" id="betweenOceanGetAddress" placeholder="Введите адрес" value="1afeHDGhf56ssfqw5fsGj1afeHDG" required>
                            <svg class="form__control-icon form__control-icon--copy">
                              <use xlink:href="#i-copy"></use>
                            </svg>
                            <svg class="form__control-icon">
                              <use xlink:href="#i-scan"></use>
                            </svg>
                          </div>
                          <div class="form-item mb-3">
                            <div class="row justify-content-center">
                              <div class="col-6">
                                <div class="qr-code">
                                  <img class="qr-code__img d-block w-100" src="@/assets/img/qr.png" alt="">
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row justify-content-center mt-3">
                            <div class="col-8">
                              <button type="submit" class="btn btn-md btn-primary form__submit w-100">
                                {{$t('closeBtn')}}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <!--modalGetBitcoin.end-->


      </div>
    </div>



    <base-modal :isShow="modalCreate.status" @close="modalCloseCreate" @ok="modalOkCreate">
      <div>
          <h2 class="modal-title mb-2">{{$t('deposit')}}</h2>
          <p class="modal__text mb-1">{{$t('stacking_avialable')}}</p>

          <div class="d-flex justify-content-between mb-3">
            <div class="modal-rend__text text-white">REND</div>
            <div class="modal-rend__value text-white">{{total.rend}}</div>
          </div>
          <div class="control control--md modal-rend__control mb-3">
            <input type="number" name="modalRend" class="control__input" v-bind:placeholder="$t('sum_place')" required v-model:value="modalCreate.amount">
            <span class="control__cur">REND</span>
          </div>
          <div class="mb-4">
            <div class="range form__range">
              <input type="range" class="range__control" name="range" step="25" min="0" :max="total.rend" v-model:value="modalCreate.amount">
            </div>
          </div>
          <div class="mb-4">
            <div class="text-grey20 mb-3">{{$t('insert_period')}}</div>
            <div class="radio-period">
              <div class="row">
                <div class="col-3">
                  <label class="radio-period__item position-relative">
                    <input type="radio" class="radio-period__control sr-only" name="period" value="365" checked required v-model="modalCreate.period">
                    <span class="radio-period__text">365 {{$t('days')}}</span>
                  </label>
                </div>
                <div class="col-3">
                  <label class="radio-period__item position-relative">
                    <input type="radio" class="radio-period__control sr-only" name="period" value="180" v-model="modalCreate.period">
                    <span class="radio-period__text">180 {{$t('days')}}</span>
                  </label>
                </div>
                <div class="col-3">
                  <label class="radio-period__item position-relative">
                    <input type="radio" class="radio-period__control sr-only" name="period" value="120" v-model="modalCreate.period">
                    <span class="radio-period__text">120 {{$t('days')}}</span>
                  </label>
                </div>
                <div class="col-3">
                  <label class="radio-period__item position-relative">
                    <input type="radio" class="radio-period__control sr-only" name="period" value="90" v-model="modalCreate.period">
                    <span class="radio-period__text">90 {{$t('days')}}</span>
                  </label>
                </div>
                <div class="col-3">
                  <label class="radio-period__item position-relative">
                    <input type="radio" class="radio-period__control sr-only" name="period" value="60" v-model="modalCreate.period">
                    <span class="radio-period__text">60 {{$t('days')}}</span>
                  </label>
                </div>
                <div class="col-3">
                  <label class="radio-period__item position-relative">
                    <input type="radio" class="radio-period__control sr-only" name="period" value="30" v-model="modalCreate.period">
                    <span class="radio-period__text">30 {{$t('days')}}</span>
                  </label>
                </div>
                <div class="col-3">
                  <label class="radio-period__item position-relative">
                    <input type="radio" class="radio-period__control sr-only" name="period" value="14" v-model="modalCreate.period">
                    <span class="radio-period__text">14 {{$t('days')}}</span>
                  </label>
                </div>
                <div class="col-3">
                  <label class="radio-period__item position-relative">
                    <input type="radio" class="radio-period__control sr-only" name="period" value="0" v-model="modalCreate.period">
                    <span class="radio-period__text">{{$t('indefinitely')}}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
      </div>

      <template v-slot:button>
        {{$t('go_new_stacking')}}
      </template>
    </base-modal>


  </main>
</template>

<script>

import HistoryStak from '@/components/StakeComponents/HistoryStak';
import PassiveStak from '@/components/StakeComponents/PassiveStak';
import StakeActive from '@/components/StakeComponents/StakeActive';
import StakingMap from '@/components/StakeComponents/StakingMap';

import BaseModal from '@/components/modals/BaseModal';

import VueApexCharts from 'vue-apexcharts';
import { commitToggleModal } from "@/store/main/mutations";
import { readUser, readUserFp } from "@/store/main/getters";

import { api } from '@/api.ts';

const cfg = {
  coin: 'USDT',
  base: 'USDT',
};

export default {
  components: {
    HistoryStak,
    PassiveStak,
    StakeActive,
    StakingMap,
    VueApexCharts,
    BaseModal
  },
  name: 'Stake',
  data() { return {
    disabled: false,
    symbol: {
      base: cfg.base,
      coin: cfg.coin,
    },
    activeTabStaking: 0,
    coins: [],
    explorer: '',
    range__control: 0,
    total: { frozen: 0, free: 0, pnl: 0, rend: 0, rend_usd: 0, available: 0, stake: 0 },
    tab: {
      main: {
        active: 0,
        items: ['balance', 'historydeposit', 'historytransfer', 'historywithdraw']
      },
    },
    all_percent: 0,
    stake: { active: [], passive: [] },
    history: { stak: [1] },
    staking: { map: [] },
    chartStaking: {
      options: {
        chart: {
            height: 150,
            width: 335,
            type: 'donut',
        },
        plotOptions: {
            pie: {
                startAngle: 0,
                endAngle: 360,
                size: '85%',
                offsetX: -25,
                offsetY: 0,
            }
        },
        labels: [this.$t('available_for_stacking'), this.$t('now_on_stacking')],
        dataLabels: {
            enabled: !0,
            formatter: function (e) {
                return e + "%";
            },
            offsetY: -20,
            hideOverflowingLabels: true,
            style: {
                fontSize: "0px",
                colors: ["#304758"]
            },
        },
        stroke: {
            width: 0,
        },
        colors: ['#00C273', '#0088DC'],
        legend: {
            show: !0,
            position: "right",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: !1,
            fontSize: "11px",
            fontWeight: 600,
            fontFamily: "Open Sans",
            offsetX: 25,
            offsetY: -24,
            labels: {
                colors: ["#676F9D"],
                useSeriesColors: false
            },
            markers: {
                width: 6,
                height: 6
            },
        },
      },
      series: [ 0, 0 ],
    },
    modalCreate: {
      status: false,
      amount: 0,
      period: 365
    },
    modalStatus: false,
  }},

  computed: {
    user() {
      return readUser( this.$store );
    }
  },

  methods: {
    checkAuth() {
      if ( this.user.hash ) return ( this.auth = true );
      commitToggleModal( this.$store, "login" );
      return false;
    },

    changeActiveTabStaking(tab) {
      this.activeTabStaking = tab;
    },

    getInfo() {
      this.coins = [];
      this.$noty.info(this.$t('loading'), { timeout: 4000, progressBar: true });

      api('stake', 'getInfo')
      .then( r => {
        console.log('getInfo', r);
        if (r.code === 1) return this.$noty.error((this.$t(r.data.error) || this.$t('smthwrong')) + (r.data.msg || ''), { timeout: 4000, progressBar: true });
        if (r.code !== 2 || typeof(r.data) !== 'object') return false;
        this.total = r.data.total;
        this.range__control = 0;

        this.chartStaking.series = [parseFloat(this.total.rend), parseFloat(this.total.stake)];
      });
    },

    getStake() {
      api('stake', 'getStake', {type: 'all'})
      .then( r => {
        console.log('getStake', r);
        this.stake.active = r.data.history.list || [];
        this.all_percent = r.data.percent.toFixed(2);
      });
    },

    getHistory() {
      api('stake', 'getHistory', {type: 'all'})
      .then( r => {
         //console.log('MYgetHistory', r);
        this.history.stak = r.data.list || [];
      });
    },

    getPassive() {
      api('stake', 'getPassive', {type: 'all'})
      .then( r => {
        // console.log('getPassive', r);
        this.stake.passive = r.data.history.list || [];
      });
    },

    getStakingMap() {
      this.staking.map = [];
      api('stake', 'stakingMap')
      .then( r => {
        // console.log('stakingMap', r);
        this.staking.map = r.data.map || [];
      });
    },

    createStack(){
      if(this.modalCreate.amount === 0) { return this.$noty.error(this.$t('wrongAmount'), { timeout: 2000, progressBar: true })};
      if(this.modalCreate.amount < 100) { return this.$noty.error(this.$t('minAmount') + 100, { timeout: 3000, progressBar: true })};

      this.$noty.info(this.$t("wait"), { timeout: 4000, progressBar: true });
      return api('stake', 'addStack', {id: 0, amount: this.modalCreate.amount, period: this.modalCreate.period})
      .then( r => {
         console.log(r);
        if(r.data.status){
          this.$noty.success(this.$t('success'), { timeout: 2000, progressBar: true });
          return true;
        }else{
          this.$noty.error(r.data.message, { timeout: 2000, progressBar: true });
        }
      });
    },

    modalCloseCreate() {
      this.modalCreate.status = false;
    },

    async modalOkCreate() {
      const response = await this.createStack();
      if(response === true) {
         this.getStake();
         this.getPassive();
         this.getInfo();
         this.modalCreate.status = false;
       }
    },

    sendModalStackEdit() {
      this.getStake();
      this.getPassive();
      this.getInfo();
      this.getHistory();
    },

    sendModalStackDelete() {
      this.getStake();
      this.getPassive();
      this.getInfo();
      this.getHistory();
    }
  },
  created() {
    if (this.checkAuth()) {
      this.getInfo();
      this.getStake();
      this.getStakingMap();
      this.getPassive();
      this.getHistory();
    }
  },
}
</script>

<style scoped>

</style>
