<template>
  <tr class="smart-table__row">
  	<td class="smart-table__cell" data-column-id="staiking">
  		<span>{{ $t('exchange__title1') }} №{{data.sid}}</span>
  	</td>
  	<td class="smart-table__cell text-right" data-column-id="sum">
  		<span>{{data.sum}}</span>
  	</td>
  	<td class="smart-table__cell text-grey40" data-column-id="date">
  		<span>{{data.date}}</span>
  	</td>
  	<td class="smart-table__cell text-right text-grey40" data-column-id="time">
  		<span>{{data.time}}</span>
  	</td>
  </tr>
</template>

<script>
import logoUSDT from '@/assets/img/coins/usdt.svg';
import logoSTAB from '@/assets/img/coins/stab.svg';
import logoREND from '@/assets/img/coins/rend.svg';
import {readSymbol} from '@/store/main/getters';

export default {
  name: 'HistoryStak',
  props: ['data'],
  // data() { return {
  //   id: 0, // spa.component.id,
  // }},
  computed: {
    url() {
      return this.data.hash;
    },
    symbol() {
      return readSymbol(this.$store);
    },
    logo() {
      if (this.symbol === 'usdt') {
        return logoUSDT;
      } else if (this.symbol === 'stab') {
        return logoSTAB;
      } else if (this.symbol === 'rend') {
        return logoREND;
      }
    },
  },
}
</script>

<style scoped>

</style>
