<template>
<tr class="smart-table__row" style="padding: 0;">
	<td class="smart-table__cell" data-column-id="rend">
		<span>{{data.rend}}</span>
	</td>
	<td class="smart-table__cell text-right" data-column-id="days">
		<span>{{data.day365}}%</span>
	</td>
	<td class="smart-table__cell text-right" data-column-id="days">
		<span>{{data.day180}}%</span>
	</td>
	<td class="smart-table__cell text-right" data-column-id="days">
		<span>{{data.day120}}%</span>
	</td>
	<td class="smart-table__cell text-right" data-column-id="days">
		<span>{{data.day90}}%</span>
	</td>
	<td class="smart-table__cell text-right" data-column-id="days">
		<span>{{data.day60}}%</span>
	</td>
	<td class="smart-table__cell text-right" data-column-id="days">
		<span>{{data.day30}}%</span>
	</td>
	<td class="smart-table__cell text-right" data-column-id="days">
		<span>{{data.day14}}%</span>
	</td>
	<td class="smart-table__cell text-right" data-column-id="days">
		<span>{{data.day_all}}%</span>
	</td>
</tr>
</template>

<script>
import logoUSDT from '@/assets/img/coins/usdt.svg';
import logoSTAB from '@/assets/img/coins/stab.svg';
import logoREND from '@/assets/img/coins/rend.svg';
import {readSymbol} from '@/store/main/getters';

export default {
  name: 'StakingMap',
  props: ['data'],
  // data() { return {
  //   // stamp: this.stamp2date(this.data.stamp),
  //   // id: 0, // spa.component.id,
  // }},
  computed: {
    url() {
      return this.data.hash;
    },
    symbol() {
      return readSymbol(this.$store);
    },
    logo() {
      if (this.symbol === 'usdt') {
        return logoUSDT;
      } else if (this.symbol === 'stab') {
        return logoSTAB;
      } else if (this.symbol === 'rend') {
        return logoREND;
      }
    },
  },
}
</script>

<style scoped>

</style>
