<template>
<div class="pt-2 pb-2">
	<div class="d-flex justify-content-between">
		<div class="pe-3">
			<p class="staking__number mt-0 mb-0 text-grey10">{{ $t('exchange__title1') }} №{{data.id}}</p>
			<p class="mt-0 mb-0 text-grey40">{{ $t('opentitle') }} {{data.datetime_start}}</p>
			<p class="mt-0 mb-0 text-grey40">{{ $t('closetitle') }} {{data.locked_date}}</p>
		</div>
		<div class="text-right pe-lg-3">
			<p class="mt-0 mb-0">&nbsp</p>
			<p class="mt-0 mb-0 text-grey40">{{data.deposit}}</p>
			<p class="mt-0 mb-0 text-grey40">{{data.deposit_stop_stek}}</p>
		</div>
	</div>
</div>
</template>

<script>
import logoUSDT from '@/assets/img/coins/usdt.svg';
import logoSTAB from '@/assets/img/coins/stab.svg';
import logoREND from '@/assets/img/coins/rend.svg';
import {readSymbol} from '@/store/main/getters';

export default {
  name: 'PassiveStak',
  props: ['data'],
  // data() { return {
  //   // stamp: this.stamp2date(this.data.stamp),
  //   id: 0, // spa.component.id,
  // }},
  computed: {
    url() {
      return this.data.hash;
    },
    symbol() {
      return readSymbol(this.$store);
    },
    logo() {
      if (this.symbol === 'usdt') {
        return logoUSDT;
      } else if (this.symbol === 'stab') {
        return logoSTAB;
      } else if (this.symbol === 'rend') {
        return logoREND;
      }
    },
  },
}
</script>

<style scoped>

</style>
